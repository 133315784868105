// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBuqhbdqBKcIwW9W_HPeNAS7SP5uWU8qLg",
    authDomain: "ottush-delight.firebaseapp.com",
    projectId: "ottush-delight",
    storageBucket: "ottush-delight.firebasestorage.app",
    messagingSenderId: "527498497351",
    appId: "1:527498497351:web:7f075211b70406f34cd062",
    measurementId: "G-MJYYK67RMT"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { app, auth, firestore, storage };
