<template>
  <v-container fluid class="size" :class="{ 'size-md': $vuetify.display.mdAndDown }">
    <v-row>
      <v-carousel height="500" class="carousel" hide-delimiter-background cycle :interval="10000" :transition="500" show-arrows="hover">
        <template v-slot:prev="{ props }">
          <v-btn variant="plain" class="content-icon" @click="props.onClick">
            <v-icon>mdi:mdi-chevron-left</v-icon>
          </v-btn>
        </template>
        <template v-slot:next="{ props }">
          <v-btn variant="plain" class="content-icon" @click="props.onClick">
            <v-icon>mdi:mdi-chevron-right</v-icon>
          </v-btn>
        </template>
        <v-carousel-item v-for="(slide, i) in slides" :key="slide.id || i" height="100%" cover>
          <div class="image-container">
            <v-img cover :src="slide.imageUrl">
              <template v-slot:placeholder>
                <v-row align="center" class="fill-height ma-0" justify="center">
                  <v-progress-circular color="grey-lighten-5" indeterminate></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>
          <div class="text-container">
            <div class="text">
              <span class="quote">“</span>
              <div class="review-text">
                <p>{{ slide.slideText }}</p>
                <p>{{ slide.slideSubtext }}</p>
              </div>
              <p class="name">- {{ slide.author }} - {{ slide.location }}.</p>
              <span class="quote">”</span>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </v-row>
  </v-container>
</template>

<script>
import { firestore } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export default {
  name: 'CarouselShow',
  data() {
    return {
      slides: [],
    };
  },
  async mounted() {
    try {
      const q = query(collection(firestore, 'pageContent'), where('page', '==', 'Reviews Carousel'));
      const querySnapshot = await getDocs(q);
      const slidesArray = [];
      querySnapshot.forEach((docSnap) => {
        const slide = docSnap.data();
        slide.id = docSnap.id;
        slidesArray.push(slide);
      });
      if (slidesArray.length > 0) {
        this.slides = slidesArray;
      } else {
        this.slides = [{
          imageUrl: 'https://res.cloudinary.com/dqvzmuz7i/image/upload/v1731667611/ottush_back_drop_amfzhc.png',
          slideText: 'Default Slide Text',
          slideSubtext: '',
          author: '',
          location: '',
        }];
      }
    } catch (error) {
      console.error('Error fetching carousel content:', error);
    }
  },
};
</script>

<style scoped>
.size-md {
  margin-top: 50px !important;
}
.size {
  padding: 0;
  margin-top: 50px;
}
.carousel {
  width: 100vw;
}
.content-icon {
  font-size: 30px;
  background: none !important;
  box-shadow: none !important;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.v-carousel-item:hover .content-icon {
  opacity: 1;
}
.image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(1px);
}
.text-container {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  text-align: center;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-family: montserratRegular;
  font-weight: bolder;
  color: #000;
  margin: 0;
  position: relative;
}
.review-text {
  margin: 0;
  line-height: 1.4;
  font-size: 1em;
}
.quote {
  font-size: 6em;
  font-family: NewYork;
  margin: 0;
  line-height: 0.8;
  padding: 0;
}
.quote:first-of-type {
  position: absolute;
  top: -20px;
  left: -65px;
}
.quote:last-of-type {
  position: absolute;
  bottom: -50px;
  right: -65px;
}
.name {
  font-size: 1em;
  margin-top: 10px;
}
.author {
  font-weight: bold;
}
@media (max-width: 450px) {
  .text {
    font-size: 1.2em;
    color: black;
  }
  .quote {
    font-size: 4em;
  }
  .image-container {
    display: none;
  }
  .text-container {
    background-image: url('https://res.cloudinary.com/dqvzmuz7i/image/upload/f_auto,q_auto/zkgwziijuz1qosnrg4ig');
    background-size: cover;
    background-position: center;
  }
}
</style>
