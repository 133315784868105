<template>
  <v-container fluid class="container">
    <v-lazy transition="fade">
      <v-row>
        <!-- Who We Are Section -->
        <v-col id="WeAre" cols="auto">
          <div class="content">
            <h4 class="title">{{ pageContent.header || 'Who We Are' }}</h4>
            <v-lazy transition="slide-x-reverse-transition" :options="{ threshold: 0.5 }">
              <p style="text-align: center;">{{ pageContent.paragraph || 'Ottush Delight' }}</p>
            </v-lazy>
          </div>
        </v-col>

        <!-- Our Brand Story Section -->
        <v-col id="OurBrandStory" cols="auto">
          <div class="content">
            <h4 class="title">Our Brand Story</h4>
            <v-lazy transition="slide-x-reverse-transition" :options="{ threshold: 0.5 }">
              <p style="text-align: center;">{{ pageContent.ourBrandStory || 'Default Brand Story text.' }}</p>
            </v-lazy>
          </div>
        </v-col>

         <!-- Meet Our Founder Section -->
        <v-col id="MeetOurFounder" cols="12">
          <div class="content">
            <h4 class="title">Meet Our Founder</h4>
            <v-row class="mt-4" align="center">
              <v-col :cols="$vuetify.display.mdAndDown ? 12 : 8">
                    <p style="text-align: start; padding-right: 10px">
                    <span class="span">Bodunrin Ottu - Founder &amp; CEO</span> <br> {{ pageContent.meetOurFounder || 'Default founder information.' }}
                  </p>
    
              </v-col>
              <v-col class="img" :cols="$vuetify.display.mdAndDown ? 12 : 4">
                <v-lazy transition="slide-x-reverse-transition" :options="{ threshold: 0.5 }">
                  <v-img class="bd-rad" :aspect-ratio="1" :src="pageContent.founderImage || '/default-founder.jpg'" :width="$vuetify.display.smAndDown ? 300 : 400">
                    <template v-slot:placeholder>
                      <v-row align="center" class="fill-height ma-0" justify="center">
                        <v-progress-circular color="grey-lighten-5" indeterminate></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-lazy>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-lazy>
  </v-container>
</template>

<script>
import { firestore } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export default {
  name: 'WhoWeAreView',
  data() {
    return {
      pageContent: {
        header: '',
        paragraph: '',
        ourBrandStory: '',
        meetOurFounder: '',
        founderImage: '',
      },
    };
  },
  async mounted() {
    try {
      const q = query(collection(firestore, 'pageContent'), where('page', '==', 'Who we are'));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((docSnap) => {
        this.pageContent = docSnap.data();
        console.log('Fetched WhoWeAreView content:', this.pageContent);
      });
    } catch (error) {
      console.error('Error fetching WhoWeAreView content:', error);
    }
  },
};
</script>

<style scoped>
.container {
  height: auto;
  border-radius: 20px;
}
.title {
  font-size: 3em !important;
  font-family: NewYork;
  text-align: center;
}
.content p {
  line-height: 2.3rem;
  letter-spacing: 2px;
  font-size: 1.4em;
}
.img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.span {
  font-weight: 400;
  font-size: 2.5rem;
}
.bd-rad {
  border-radius: 20px;
}
</style>
