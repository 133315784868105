<!-- AdminPage.vue -->
<!-- eslint-disable vue/no-parsing-error, vue/valid-v-slot -->
<template>
  <v-container fluid>
    <v-card class="pa-4">
      <v-toolbar flat color="#FFEE58" dark>
        <v-toolbar-title :style="$vuetify.display.smAndDown ? 'font-size: 0.8rem' : 'font-size: 1.3rem' ">Admin Dashboard</v-toolbar-title>
        <v-spacer />
        <v-btn @click="logout" color="#F9A825">Logout</v-btn>
      </v-toolbar>

      <v-tabs v-model="activeTab" background-color="grey lighten-4" grow>
        <v-tab :value="0">Upload Media</v-tab>
        <v-tab :value="1">Edit Media</v-tab>
        <v-tab :value="2">Content Library</v-tab>
      </v-tabs>

      <v-tabs-window v-model="activeTab">
        <!-- Upload Media Tab -->
        <v-tabs-window-item :value="0">
          <v-form ref="uploadForm" @submit.prevent="uploadMedia" class="mt-4">
            <v-select v-model="newMedia.section" :items="mediaSections" label="Media Section" required />
            <v-text-field v-model="newMedia.title" label="Title" required />
            <v-text-field v-model="newMedia.displayName" label="Display Name" />
            <v-textarea v-model="newMedia.description" label="Description" required />
            <v-file-input
              v-model="newMedia.file"
              label="Select Image/Video"
              accept="image/*,video/*"
              required
            />
            <v-btn type="submit" color="#FFB300">Upload Media</v-btn>
          </v-form>
        </v-tabs-window-item>

        <!-- Edit Media Tab -->
        <v-tabs-window-item :value="1">
          <v-row class="mt-4" dense>
            <v-col v-for="item in mediaList" :key="item.id" cols="12" sm="6" md="4">
              <v-card>
                <v-img :src="getThumbnailUrl(item.url)" height="150px" />
                <v-card-title>{{ item.displayName || item.title }}</v-card-title>
                <v-card-text>
                  <div>{{ item.description }}</div>
                  <div><strong>Section:</strong> {{ item.section }}</div>
                </v-card-text>
                <v-card-actions>
                  <v-btn icon @click="openEditMediaDialog(item)">
                    <v-icon>mdi:mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="confirmDeletion(item, 'media')">
                    <v-icon>mdi:mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-dialog v-model="editMediaDialog" max-width="500px">
            <v-card>
              <v-card-title>Edit Media</v-card-title>
              <v-card-text>
                <v-form ref="editMediaForm" @submit.prevent="updateMedia">
                  <v-text-field v-model="editMediaData.title" label="Title" required />
                  <v-text-field v-model="editMediaData.displayName" label="Display Name" />
                  <v-textarea v-model="editMediaData.description" label="Description" required />
                  <v-select v-model="editMediaData.section" :items="mediaSections" label="Media Section" required />
                  <v-file-input v-model="editMediaData.file" label="Replace File (optional)" accept="image/*,video/*" />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="editMediaDialog = false">Cancel</v-btn>
                <v-btn color="#FFB300" @click="updateMedia">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tabs-window-item>

        <!-- Content Library Tab -->
        <v-tabs-window-item :value="2">
          <v-form ref="pageContentForm" @submit.prevent="savePageContent" class="mt-4">
            <v-select v-model="newPageContent.page" :items="pageList" label="Select Page" required />
            <!-- Only show generic fields if neither Reviews Carousel nor Who we are is selected -->
            <template v-if="isGenericForm">
              <!-- <v-text-field v-model="newPageContent.header" label="Header Text" /> -->
              <v-textarea v-model="newPageContent.paragraph" label="Paragraph Content" rows="4" />
              <v-file-input v-model="newPageContent.file" label="Upload Image" accept="image/*" />
              <v-text-field v-model="newPageContent.overlayMessages" label="Overlay Messages (comma separated)" />
            </template>
            <!-- Who we are form -->
            <template v-else-if="isWhoWeAre">
              <v-textarea v-model="newPageContent.paragraph" label="Who we are description" rows="4" />
              <v-textarea v-model="newPageContent.ourBrandStory" label="Our Brand Story" rows="3" />
              <v-textarea v-model="newPageContent.meetOurFounder" label="Meet Our Founder" rows="3" />
              <v-file-input v-model="newPageContent.founderImage" label="Founder Image" accept="image/*" />
            </template>
            <!-- Reviews Carousel form -->
            <template v-else-if="isReviewsCarousel">
              <v-text-field v-model="newPageContent.slideText" label="Slide Text" />
              <v-text-field v-model="newPageContent.slideSubtext" label="Slide Subtext" />
              <v-text-field v-model="newPageContent.author" label="Author" />
              <v-text-field v-model="newPageContent.location" label="Location" />
            </template>
            <v-btn type="submit" color="#FFB300">Save Content Library</v-btn>
          </v-form>

          <v-row class="mt-4" dense>
            <v-col v-for="item in pageContentList" :key="item.id" cols="12" sm="6" md="4">
              <v-card>
                <v-img v-if="item.imageUrl" :src="item.imageUrl" height="150px" />
                <v-card-title>{{ item.header || item.page }}</v-card-title>
                <v-card-text>
                  <div v-if="item.page === 'Reviews Carousel'">
                    <div><strong>Slide Text:</strong> {{ item.slideText }}</div>
                    <div><strong>Slide Subtext:</strong> {{ item.slideSubtext }}</div>
                    <div><strong>Author &amp; Location:</strong> - {{ item.author }} - {{ item.location }}.</div>
                  </div>
                  <div v-else-if="item.page === 'Who we are'">
                    <div><strong>Our Brand Story:</strong> {{ item.ourBrandStory }}</div>
                    <div><strong>Meet Our Founder:</strong> {{ item.meetOurFounder }}</div>
                  </div>
                  <div v-else>
                    <div>{{ item.paragraph }}</div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn icon @click="openEditPageContentDialog(item)">
                    <v-icon>mdi:mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="confirmDeletion(item, 'content')">
                    <v-icon>mdi:mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog v-model="editPageContentDialog" max-width="500px">
            <v-card>
              <v-card-title>Edit Content Library</v-card-title>
              <v-card-text>
                <v-form ref="editPageContentForm" @submit.prevent="updatePageContent">
                  <v-select v-model="editPageContentData.page" :items="pageList" label="Select Page" required />
                  <template v-if="isGenericEditForm">
                    <!-- <v-text-field v-model="editPageContentData.header" label="Header Text" /> -->
                    <v-textarea v-model="editPageContentData.paragraph" label="Paragraph Content" rows="4" />
                    <v-file-input v-model="editPageContentData.file" label="Replace Image (optional)" accept="image/*" />
                    <v-text-field v-model="editPageContentData.overlayMessages" label="Overlay Messages (comma separated)" />
                  </template>
                  <template v-else-if="isWhoWeAreEditForm">
                    <v-textarea v-model="editPageContentData.paragraph" label="Who we are description" rows="4" />
                    <v-textarea v-model="editPageContentData.ourBrandStory" label="Our Brand Story" rows="3" />
                    <v-textarea v-model="editPageContentData.meetOurFounder" label="Meet Our Founder" rows="3" />
                    <v-file-input v-model="editPageContentData.founderImageFile" label="Replace Founder Image (optional)" accept="image/*" />
                  </template>
                  <template v-else-if="isReviewsCarouselEditForm">
                    <v-text-field v-model="editPageContentData.slideText" label="Slide Text" />
                    <v-text-field v-model="editPageContentData.slideSubtext" label="Slide Subtext" />
                    <v-text-field v-model="editPageContentData.author" label="Author" />
                    <v-text-field v-model="editPageContentData.location" label="Location" />
                  </template>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="editPageContentDialog = false">Cancel</v-btn>
                <v-btn color="#FFB300" @click="updatePageContent">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card>

    <!-- Confirmation Dialog for Deletion -->
    <v-dialog v-model="deletionDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text>
          Are you sure you want to delete this {{ deletionType === 'media' ? 'media item' : 'content item' }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="cancelDeletion">Cancel</v-btn>
          <v-btn color="red darken-1" @click="deleteConfirmed">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar for notifications -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" top timeout="5000">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { signOut } from 'firebase/auth';
import { auth, firestore } from '@/firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';

export default {
  name: 'AdminPage',
  data() {
    return {
      activeTab: 0,
      mediaSections: ['Slideshow', 'Home Video'],
      newMedia: { title: '', displayName: '', description: '', file: null, section: '' },
      mediaList: [],
      mediaHeaders: [
        { text: 'Title', value: 'title' },
        { text: 'Display Name', value: 'displayName' },
        { text: 'Thumbnail', value: 'thumbnail', sortable: false },
        { text: 'Section', value: 'section' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editMediaDialog: false,
      editMediaData: { id: '', title: '', displayName: '', description: '', section: '', file: null, url: '' },
      pageList: ['Event Beverage', 'Celebration Packages', 'Signature Mocktails', 'Who we are', 'Reviews Carousel'],
      newPageContent: {
        page: '',
        header: '',
        paragraph: '',
        file: null,
        overlayMessages: '',
        // For Reviews Carousel:
        slideText: '',
        slideSubtext: '',
        author: '',
        location: '',
        // For Who we are:
        ourBrandStory: '',
        meetOurFounder: '',
        founderImage: null,
      },
      pageContentList: [],
      pageContentHeaders: [
        { text: 'Page', value: 'page' },
        { text: 'Header', value: 'header' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editPageContentDialog: false,
      editPageContentData: {
        id: '',
        page: '',
        header: '',
        paragraph: '',
        file: null,
        overlayMessages: '',
        imageUrl: '',
        // For Reviews Carousel:
        slideText: '',
        slideSubtext: '',
        author: '',
        location: '',
        // For Who we are:
        ourBrandStory: '',
        meetOurFounder: '',
        founderImageFile: null,
      },
      snackbar: { show: false, message: '', color: 'success' },
      deletionDialog: false,
      deletionTarget: null,
      deletionType: '', // 'media' or 'content'
    };
  },
  computed: {
    // For new content form
    isWhoWeAre() {
      return this.newPageContent.page === 'Who we are';
    },
    isReviewsCarousel() {
      return this.newPageContent.page === 'Reviews Carousel';
    },
    isGenericForm() {
      return !this.isWhoWeAre && !this.isReviewsCarousel;
    },
    // For edit content form
    isWhoWeAreEditForm() {
      return this.editPageContentData.page === 'Who we are';
    },
    isReviewsCarouselEditForm() {
      return this.editPageContentData.page === 'Reviews Carousel';
    },
    isGenericEditForm() {
      return !this.isWhoWeAreEditForm && !this.isReviewsCarouselEditForm;
    },
  },
  methods: {
    async logout() {
      await signOut(auth);
      this.showSnackbar("Logout Successful!", "success");
      this.$router.push('/admin-login');
    },
    showSnackbar(message, color = 'success') {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    // Helper to get thumbnail URL from Cloudinary URL
    getThumbnailUrl(url) {
      if (!url) return '';
      return url.replace('/upload/', '/upload/w_100,h_100,c_thumb/');
    },
    // Media methods
    async uploadMedia() {
      if (!this.newMedia.file) return;
      try {
        const formData = new FormData();
        formData.append('file', this.newMedia.file);
        formData.append('folder', `media/${this.newMedia.section}`);
        formData.append('upload_preset', 'ottush');
        const response = await fetch('https://api.cloudinary.com/v1_1/dqvzmuz7i/upload', { method: 'POST', body: formData });
        const data = await response.json();
        const url = data.secure_url;
        await addDoc(collection(firestore, 'media'), {
          title: this.newMedia.title,
          displayName: this.newMedia.displayName,
          description: this.newMedia.description,
          section: this.newMedia.section,
          url,
          createdAt: new Date(),
        });
        this.showSnackbar("Media Uploaded Successfully!");
        this.newMedia = { title: '', displayName: '', description: '', file: null, section: '' };
        this.fetchMedia();
      } catch (error) {
        console.error('Upload error:', error);
        this.showSnackbar("Media Upload Failed!", "error");
      }
    },
    async fetchMedia() {
      const querySnapshot = await getDocs(collection(firestore, 'media'));
      this.mediaList = [];
      querySnapshot.forEach((docSnap) => {
        let mediaItem = docSnap.data();
        mediaItem.id = docSnap.id;
        this.mediaList.push(mediaItem);
      });
    },
    openEditMediaDialog(item) {
      this.editMediaData = { ...item, file: null };
      this.editMediaDialog = true;
    },
    async updateMedia() {
      try {
        let updateData = {
          title: this.editMediaData.title,
          displayName: this.editMediaData.displayName,
          description: this.editMediaData.description,
          section: this.editMediaData.section,
        };
        if (this.editMediaData.file) {
          const formData = new FormData();
          formData.append('file', this.editMediaData.file);
          formData.append('folder', `media/${this.editMediaData.section}`);
          formData.append('upload_preset', 'ottush');
          const response = await fetch('https://api.cloudinary.com/v1_1/dqvzmuz7i/upload', { method: 'POST', body: formData });
          const data = await response.json();
          updateData.url = data.secure_url;
        }
        await updateDoc(doc(firestore, 'media', this.editMediaData.id), updateData);
        this.showSnackbar("Media Updated Successfully!");
        this.editMediaDialog = false;
        this.fetchMedia();
      } catch (error) {
        console.error('Update error:', error);
        this.showSnackbar("Media Update Failed!", "error");
      }
    },
    async deleteMedia(item) {
      // Instead of directly deleting, open confirmation
      this.confirmDeletion(item, 'media');
    },
    // Content Library methods
    async savePageContent() {
      try {
        let imageUrl = '';
        if (this.newPageContent.file) {
          const formData = new FormData();
          formData.append('file', this.newPageContent.file);
          formData.append('folder', `pageContent/${this.newPageContent.page}`);
          formData.append('upload_preset', 'ottush');
          const response = await fetch('https://api.cloudinary.com/v1_1/dqvzmuz7i/upload', { method: 'POST', body: formData });
          const data = await response.json();
          imageUrl = data.secure_url;
        }
        // For Reviews Carousel, if no image is uploaded, use default background
        if (this.newPageContent.page === 'Reviews Carousel' && !imageUrl) {
          imageUrl = 'https://res.cloudinary.com/dqvzmuz7i/image/upload/v1731667611/ottush_back_drop_amfzhc.png';
        }
        const overlayArr = this.newPageContent.overlayMessages
          ? this.newPageContent.overlayMessages.split(',').map(s => s.trim())
          : [];
        const contentData = {
          page: this.newPageContent.page,
          header: this.newPageContent.header,
          paragraph: this.newPageContent.paragraph,
          overlayMessages: overlayArr,
          imageUrl,
          updatedAt: new Date(),
        };
        if (this.newPageContent.page === 'Reviews Carousel') {
          contentData.slideText = this.newPageContent.slideText;
          contentData.slideSubtext = this.newPageContent.slideSubtext;
          contentData.author = this.newPageContent.author;
          contentData.location = this.newPageContent.location;
        }
        if (this.newPageContent.page === 'Who we are') {
          contentData.ourBrandStory = this.newPageContent.ourBrandStory;
          contentData.meetOurFounder = this.newPageContent.meetOurFounder;
          if (this.newPageContent.founderImage) {
            const formDataFounder = new FormData();
            formDataFounder.append('file', this.newPageContent.founderImage);
            formDataFounder.append('folder', `pageContent/${this.newPageContent.page}/founder`);
            formDataFounder.append('upload_preset', 'ottush');
            const responseFounder = await fetch('https://api.cloudinary.com/v1_1/dqvzmuz7i/upload', { method: 'POST', body: formDataFounder });
            const dataFounder = await responseFounder.json();
            contentData.founderImage = dataFounder.secure_url;
          }
        }
        await addDoc(collection(firestore, 'pageContent'), contentData);
        this.showSnackbar("Content Saved Successfully!");
        this.newPageContent = {
          page: '',
          header: '',
          paragraph: '',
          file: null,
          overlayMessages: '',
          slideText: '',
          slideSubtext: '',
          author: '',
          location: '',
          ourBrandStory: '',
          meetOurFounder: '',
          founderImage: null,
        };
        this.fetchPageContent();
      } catch (error) {
        console.error('Save content error:', error);
        this.showSnackbar("Content Save Failed!", "error");
      }
    },
    async fetchPageContent() {
      const querySnapshot = await getDocs(collection(firestore, 'pageContent'));
      this.pageContentList = [];
      querySnapshot.forEach((docSnap) => {
        let contentItem = docSnap.data();
        contentItem.id = docSnap.id;
        contentItem.overlayMessagesString = contentItem.overlayMessages ? contentItem.overlayMessages.join(', ') : '';
        this.pageContentList.push(contentItem);
      });
    },
    openEditPageContentDialog(item) {
      this.editPageContentData = {
        id: item.id,
        page: item.page,
        header: item.header,
        paragraph: item.paragraph,
        overlayMessages: item.overlayMessages ? item.overlayMessages.join(', ') : '',
        imageUrl: item.imageUrl,
        slideText: item.slideText || '',
        slideSubtext: item.slideSubtext || '',
        author: item.author || '',
        location: item.location || '',
        ourBrandStory: item.ourBrandStory || '',
        meetOurFounder: item.meetOurFounder || '',
        founderImage: item.founderImage || '',
        founderImageFile: null,
        file: null,
      };
      this.editPageContentDialog = true;
    },
    async updatePageContent() {
      try {
        let updateData = {
          page: this.editPageContentData.page,
          header: this.editPageContentData.header,
          paragraph: this.editPageContentData.paragraph,
          overlayMessages: this.editPageContentData.overlayMessages
            ? this.editPageContentData.overlayMessages.split(',').map(s => s.trim())
            : [],
        };
        if (this.editPageContentData.file) {
          const formData = new FormData();
          formData.append('file', this.editPageContentData.file);
          formData.append('folder', `pageContent/${this.editPageContentData.page}`);
          formData.append('upload_preset', 'ottush');
          const response = await fetch('https://api.cloudinary.com/v1_1/dqvzmuz7i/upload', { method: 'POST', body: formData });
          const data = await response.json();
          updateData.imageUrl = data.secure_url;
        }
        if (this.editPageContentData.page === 'Reviews Carousel') {
          updateData.slideText = this.editPageContentData.slideText;
          updateData.slideSubtext = this.editPageContentData.slideSubtext;
          updateData.author = this.editPageContentData.author;
          updateData.location = this.editPageContentData.location;
        }
        if (this.editPageContentData.page === 'Who we are') {
          updateData.ourBrandStory = this.editPageContentData.ourBrandStory;
          updateData.meetOurFounder = this.editPageContentData.meetOurFounder;
          if (this.editPageContentData.founderImageFile) {
            const formDataFounder = new FormData();
            formDataFounder.append('file', this.editPageContentData.founderImageFile);
            formDataFounder.append('folder', `pageContent/${this.editPageContentData.page}/founder`);
            formDataFounder.append('upload_preset', 'ottush');
            const responseFounder = await fetch('https://api.cloudinary.com/v1_1/dqvzmuz7i/upload', { method: 'POST', body: formDataFounder });
            const dataFounder = await responseFounder.json();
            updateData.founderImage = dataFounder.secure_url;
          }
        }
        await updateDoc(doc(firestore, 'pageContent', this.editPageContentData.id), updateData);
        this.showSnackbar("Content Updated Successfully!");
        this.editPageContentDialog = false;
        this.fetchPageContent();
      } catch (error) {
        console.error('Update content error:', error);
        this.showSnackbar("Content Update Failed!", "error");
      }
    },
    async deletePageContent(item) {
      // Instead of directly deleting, open confirmation
      this.confirmDeletion(item, 'content');
    },
    // Deletion confirmation methods
    confirmDeletion(item, type) {
      this.deletionTarget = item;
      this.deletionType = type;
      this.deletionDialog = true;
    },
    async deleteConfirmed() {
      if (this.deletionType === 'media') {
        try {
          await deleteDoc(doc(firestore, 'media', this.deletionTarget.id));
          this.showSnackbar("Media Deleted Successfully!");
          this.fetchMedia();
        } catch (error) {
          console.error('Delete error:', error);
          this.showSnackbar("Media Delete Failed!", "error");
        }
      } else if (this.deletionType === 'content') {
        try {
          await deleteDoc(doc(firestore, 'pageContent', this.deletionTarget.id));
          this.showSnackbar("Content Deleted Successfully!");
          this.fetchPageContent();
        } catch (error) {
          console.error('Delete content error:', error);
          this.showSnackbar("Content Delete Failed!", "error");
        }
      }
      this.deletionDialog = false;
      this.deletionTarget = null;
      this.deletionType = '';
    },
    cancelDeletion() {
      this.deletionDialog = false;
      this.deletionTarget = null;
      this.deletionType = '';
    },
  },
  async mounted() {
    this.fetchMedia();
    this.fetchPageContent();
  },
};
</script>

<style scoped>
* {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
