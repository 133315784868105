// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import WhoWeAreView from "../views/WhoWeAreView.vue";
import CelebrationPack from "../views/CelebrationPack.vue";
import EventBev from "../views/EventBev.vue";
import SignatureMocktails from "../views/SignatureMocktails.vue";
import AdminLogin from "../views/AdminLogin.vue";
import AdminPage from "../views/AdminPage.vue";
import { auth } from '@/firebase';

const routes = [
  { path: "/", name: "home", component: HomeView },
  { path: '/whoweare/:sectionId', name: 'WhoWeAre', component: WhoWeAreView },
  { path: '/services/CelebrationPackages', name: 'CelebrationPackages', component: CelebrationPack },
  { path: '/services/EventBeverage', name: 'EventBeverage', component: EventBev },
  { path: '/services/SignatureMocktails', name: 'SignatureMocktails', component: SignatureMocktails },
  { path: '/admin-login', name: 'AdminLogin', component: AdminLogin },
  { path: '/admin', name: 'AdminPage', component: AdminPage, meta: { requiresAuth: true } },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return { el: to.hash, behavior: 'smooth' };
    if (savedPosition) return savedPosition;
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const currentUser = auth.currentUser;
  if (requiresAuth && !currentUser) {
    next('/admin-login');
  } else {
    next();
  }
});

export default router;
