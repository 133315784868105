<!-- <template>
  <v-container fluid class="slideshow-container" :class="{ 'slideshow-md-container': $vuetify.display.mdAndDown }">
    <v-row class="slideshow-row" no-gutters ref="slideshowRow" @scroll="checkScrollPosition">
      <v-col
        v-for="(slide, index) in slides"
        :key="index"
        :class="['slideshow-col', { 'square-image': index % 2 === 0, 'rectangular-image': index % 2 !== 0 }]"
      >
          <v-img 
            :src="slide" 
            class="slideshow-img" 
            :class="{ 'enlarged': hoverIndex === index }" 
            @mouseover="hoverIndex = index" 
            @mouseout="hoverIndex = null" 
            @click="openFullscreen(slide)">
            <template v-slot:placeholder>
              <v-row align="center" class="fill-height ma-0" justify="center">
                <v-progress-circular color="grey-lighten-5" indeterminate></v-progress-circular>
              </v-row>
            </template>
        </v-img>
      </v-col>
    </v-row>
    <v-btn icon class="nav-btn prev" v-if="showPrevBtn" @click="scrollLeft">
      <v-icon>mdi:mdi-chevron-left</v-icon>
    </v-btn>    
    <v-btn icon class="nav-btn next" v-if="showNextBtn" @click="scrollRight">
      <v-icon>mdi:mdi-chevron-right</v-icon>
    </v-btn>
    <div v-if="fullscreenImage" class="fullscreen-overlay" @click="closeFullscreen">
      <v-btn icon class="close-fullscreen-btn" @click.stop="closeFullscreen">
        <v-icon>mdi:mdi-close</v-icon>
      </v-btn>
      <v-img :src="fullscreenImage" class="fullscreen-img"></v-img>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ContinuousSlideshow',
  data() {
    return {
      slides: [],
      fullscreenImage: null,
      showPrevBtn: false,
      showNextBtn: true,
      hoverIndex: null,
    };
  },
  mounted() {
    this.loadSlides();
  },
  methods: {
    loadSlides() {
      const jpgFolder = '/images/jpg/';
      const webpFolder = '/images/webp/';
      const jpgImages = [
        'cocktails 9.jpg', 'cocktails 10.jpg', 'cocktails 17.jpg',
        'slides 1.jpg', 
      ];
      const webpImages = [
        'cocktails 1.webp', 'cocktails 4.webp',
        'slides 1.webp', 'slides 7.webp', 'slides 2.webp','popcorn.webp', 'slides 3.webp', 'slides 4.webp', 'slides 5.webp'
      ];

      const allImages = jpgImages.map(img => jpgFolder + img).concat(webpImages.map(img => webpFolder + img));
      this.slides = this.shuffleArray(allImages);
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }  
      return array;
    },
    checkScrollPosition() {
    const container = this.$el.querySelector('.slideshow-row');
    if (container.scrollLeft > 0) {
      this.showPrevBtn = true;
    } else {
      this.showPrevBtn = false;
    }
    if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
    this.showNextBtn = false;
  } else {
    this.showNextBtn = true;
  }
},

  scrollLeft() {
    const container = this.$el.querySelector('.slideshow-row');
    container.scrollBy({ left: -300, behavior: 'smooth' });
    this.checkScrollPosition();
  },
  scrollRight() {
    const container = this.$el.querySelector('.slideshow-row');
    container.scrollBy({ left: 300, behavior: 'smooth' });
    this.checkScrollPosition();
  },
    openFullscreen(slide) {
      this.fullscreenImage = slide;
    },
    closeFullscreen() {
      this.fullscreenImage = null;
    }
  },
  enlargeImage: (slide) => {
    this.enlargedImage = slide;
  },
  resetEnlargedImage: () => {
    this.enlargedImage = null;
  } 
};
</script>

<style scoped>
.slideshow-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.slideshow-md-container {
  margin-top: 100px!important;
}

.slideshow-row {
  display: flex;
  gap: 0;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: 10px;
}

.slideshow-col {
  flex: 1 0 400px;
  width: 25%;
  margin: 0; 
  padding: 0;
}


.slideshow-row::-webkit-scrollbar {
  display: none;
}


.square-image {
  width: 600px;
  height: 425px;
}

.rectangular-image {
  width: 600px;
  height: 425px;
}

.slideshow-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.nav-btn {
  position: absolute;
  top: 50%;
  font-size: 30px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 1;
}

.prev {
  left: 10px;
}

.next {
  right: 10px; 
  z-index: 2; 
}


.fullscreen-overlay {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 20px;
}

.fullscreen-img {
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
}

.close-fullscreen-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 3;
}

.enlarged {
  transform: scale(1.2);
  transition: transform 0.5s ease;
  z-index: 1;
  overflow: hidden;
}
</style> -->

<template>
  <v-container fluid class="slideshow-container" :class="{ 'slideshow-md-container': $vuetify.display.mdAndDown }">
    <v-row class="slideshow-row" no-gutters ref="slideshowRow" @scroll="checkScrollPosition">
      <v-col
        v-for="(slide, index) in slides"
        :key="index"
        :class="['slideshow-col', { 'square-image': index % 2 === 0, 'rectangular-image': index % 2 !== 0 }]"
      >
        <v-img
          :src="slide"
          class="slideshow-img"
          :class="{ 'enlarged': hoverIndex === index }"
          @mouseover="hoverIndex = index"
          @mouseout="hoverIndex = null"
          @click="openFullscreen(slide)"
        >
          <template v-slot:placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular color="grey-lighten-5" indeterminate></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-btn icon class="nav-btn prev" v-if="showPrevBtn" @click="scrollLeft">
      <v-icon>mdi:mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn icon class="nav-btn next" v-if="showNextBtn" @click="scrollRight">
      <v-icon>mdi:mdi-chevron-right</v-icon>
    </v-btn>
    <div v-if="fullscreenImage" class="fullscreen-overlay" @click="closeFullscreen">
      <v-btn icon class="close-fullscreen-btn" @click.stop="closeFullscreen">
        <v-icon>mdi:mdi-close</v-icon>
      </v-btn>
      <v-img :src="fullscreenImage" class="fullscreen-img"></v-img>
    </div>
  </v-container>
</template>

<script>
import { firestore } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export default {
  name: 'ContinuousSlideshow',
  data() {
    return {
      slides: [],
      fullscreenImage: null,
      showPrevBtn: false,
      showNextBtn: true,
      hoverIndex: null,
    };
  },
  async mounted() {
    await this.loadSlides();
  },
  methods: {
    async loadSlides() {
      try {
        const q = query(collection(firestore, 'media'), where('section', '==', 'Slideshow'));
        const querySnapshot = await getDocs(q);
        const mediaItems = [];
        querySnapshot.forEach((docSnap) => {
          let mediaItem = docSnap.data();
          mediaItem.id = docSnap.id;
          mediaItems.push(mediaItem);
        });
        if (mediaItems.length > 0) {
          this.slides = mediaItems.map(item => item.url);
        } else {
          // Fallback image if no media is available
          this.slides = ['/images/jpg/default.jpg'];
        }
      } catch (error) {
        console.error('Error fetching ContinuousSlideshow media:', error);
      }
    },
    checkScrollPosition() {
      const container = this.$el.querySelector('.slideshow-row');
      this.showPrevBtn = container.scrollLeft > 0;
      this.showNextBtn = container.scrollLeft + container.clientWidth < container.scrollWidth;
    },
    scrollLeft() {
      const container = this.$el.querySelector('.slideshow-row');
      container.scrollBy({ left: -300, behavior: 'smooth' });
      this.checkScrollPosition();
    },
    scrollRight() {
      const container = this.$el.querySelector('.slideshow-row');
      container.scrollBy({ left: 300, behavior: 'smooth' });
      this.checkScrollPosition();
    },
    openFullscreen(slide) {
      this.fullscreenImage = slide;
    },
    closeFullscreen() {
      this.fullscreenImage = null;
    },
  },
};
</script>

<style scoped>
.slideshow-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.slideshow-md-container {
  margin-top: 100px!important;
}
.slideshow-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: 10px;
}
.slideshow-col {
  flex: 1 0 400px;
  width: 25%;
  margin: 0;
  padding: 0;
}
.slideshow-row::-webkit-scrollbar {
  display: none;
}
.square-image {
  width: 600px;
  height: 425px;
}
.rectangular-image {
  width: 600px;
  height: 425px;
}
.slideshow-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}
.nav-btn {
  position: absolute;
  top: 50%;
  font-size: 30px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 1;
}
.prev {
  left: 10px;
}
.next {
  right: 10px;
  z-index: 2;
}
.fullscreen-overlay {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 20px;
}
.fullscreen-img {
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
}
.close-fullscreen-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 3;
}
.enlarged {
  transform: scale(1.2);
  transition: transform 0.5s ease;
  z-index: 1;
  overflow: hidden;
}
</style>


<!-- 
<template>
  <v-container fluid class="slideshow-container" :class="{ 'slideshow-md-container': $vuetify.display.mdAndDown }">
    <v-row class="slideshow-row" no-gutters ref="slideshowRow" @scroll="checkScrollPosition">
      <v-col
        v-for="(slide, index) in slides"
        :key="slide.id || index"
        :class="['slideshow-col', { 'square-image': index % 2 === 0, 'rectangular-image': index % 2 !== 0 }]"
      >
        <v-img 
          :src="slide.url" 
          class="slideshow-img" 
          :class="{ 'enlarged': hoverIndex === index }" 
          @mouseover="hoverIndex = index" 
          @mouseout="hoverIndex = null" 
          @click="openFullscreen(slide.url)"
        >
          <template #placeholder>
            <v-row align="center" class="fill-height ma-0" justify="center">
              <v-progress-circular color="grey-lighten-5" indeterminate />
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-btn icon class="nav-btn prev" v-if="showPrevBtn" @click="scrollLeft">
      <v-icon>mdi:mdi-chevron-left</v-icon>
    </v-btn>    
    <v-btn icon class="nav-btn next" v-if="showNextBtn" @click="scrollRight">
      <v-icon>mdi:mdi-chevron-right</v-icon>
    </v-btn>
    <div v-if="fullscreenImage" class="fullscreen-overlay" @click="closeFullscreen">
      <v-btn icon class="close-fullscreen-btn" @click.stop="closeFullscreen">
        <v-icon>mdi:mdi-close</v-icon>
      </v-btn>
      <v-img :src="fullscreenImage" class="fullscreen-img" />
    </div>
  </v-container>
</template>

<script>
import { firestore } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export default {
  name: 'ContinuousSlideshow',
  data() {
    return {
      slides: [],
      fullscreenImage: null,
      showPrevBtn: false,
      showNextBtn: true,
      hoverIndex: null,
    };
  },
  mounted() {
    this.fetchSlides();
  },
  methods: {
    async fetchSlides() {
      try {
        const q = query(collection(firestore, 'media'), where('section', '==', 'ContinuousSlideshow'));
        const querySnapshot = await getDocs(q);
        const slidesArray = [];
        querySnapshot.forEach((docSnap) => {
          let slide = docSnap.data();
          slide.id = docSnap.id;
          slidesArray.push(slide);
        });
        this.slides = this.shuffleArray(slidesArray);
      } catch (error) {
        console.error('Error fetching slides:', error);
      }
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    checkScrollPosition() {
      const container = this.$refs.slideshowRow;
      if (container) {
        this.showPrevBtn = container.scrollLeft > 0;
        this.showNextBtn = container.scrollLeft + container.clientWidth < container.scrollWidth;
      }
    },
    scrollLeft() {
      const container = this.$refs.slideshowRow;
      if (container) {
        container.scrollBy({ left: -300, behavior: 'smooth' });
        this.checkScrollPosition();
      }
    },
    scrollRight() {
      const container = this.$refs.slideshowRow;
      if (container) {
        container.scrollBy({ left: 300, behavior: 'smooth' });
        this.checkScrollPosition();
      }
    },
    openFullscreen(url) {
      this.fullscreenImage = url;
    },
    closeFullscreen() {
      this.fullscreenImage = null;
    }
  },
};
</script>

<style scoped>
.slideshow-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.slideshow-md-container {
  margin-top: 100px !important;
}
.slideshow-row {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  gap: 10px;
}
.slideshow-col {
  flex: 1 0 400px;
  width: 25%;
  margin: 0;
  padding: 0;
}
.slideshow-row::-webkit-scrollbar {
  display: none;
}
.square-image {
  width: 600px;
  height: 425px;
}
.rectangular-image {
  width: 600px;
  height: 425px;
}
.slideshow-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}
.nav-btn {
  position: absolute;
  top: 50%;
  font-size: 30px;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 1;
}
.prev {
  left: 10px;
}
.next {
  right: 10px;
  z-index: 2;
}
.fullscreen-overlay {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 20px;
}
.fullscreen-img {
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
}
.close-fullscreen-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 3;
}
.enlarged {
  transform: scale(1.2);
  transition: transform 0.5s ease;
  z-index: 1;
  overflow: hidden;
}
</style> -->
