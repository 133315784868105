<template>
  <div style="position: relative;" :class="{'bd-rad' : $vuetify.display.mdAndUp}">
    <!-- Video or Image Container -->
    <div v-if="imageSrc || dynamicMediaUrl" class="video-container" :class="{ 'video-element-md': $vuetify.display.mdAndUp,'md-down-video-container': $vuetify.display.mdAndDown, 'sm-down-video-container': $vuetify.display.smAndDown }">
      <!-- Use admin‑provided image if available; otherwise, use dynamicMediaUrl from Firestore -->
      <img :src="imageSrc || dynamicMediaUrl" class="video-element" :class="{'md--video-element' : $vuetify.display.mdAndUp}" alt="Ottush Delight services" />
    </div>
    <div v-else class="video-container" :class="{'video-element-md': $vuetify.display.mdAndUp, 'md-down-video-container': $vuetify.display.mdAndDown, 'sm-down-video-container': $vuetify.display.smAndDown }">
      <!-- Show video when no imageSrc is provided -->
      <video ref="videoPlayer" class="video-element" :class="{'md--video-element' : $vuetify.display.mdAndUp}" loop muted preload="auto" playsinline></video>
    </div>

    <!-- Overlay Text -->
    <div v-if="showOverlay" class="overlay">
      <h2>
        <div
          :class="{ 'md-down-overlay-text': $vuetify.display.mdAndDown, 'sm-down-overlay-text': $vuetify.display.smAndDown }"
          v-for="(text, index) in overlayText"
          :key="index"
          class="overlay-text-item"
        >
          {{ text }}
        </div>
      </h2>
    </div>
  </div>
</template>

<script>
import { firestore } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export default {
  name: "MenuVid",
  props: {
    videos: {
      type: Array,
      required: false,
      default: () => [
        { src: "https://res.cloudinary.com/dqvzmuz7i/video/upload/f_auto:video,q_auto/q1xl3wbizezk8xpmqzqs" },
        { src: "https://res.cloudinary.com/dqvzmuz7i/video/upload/f_auto:video,q_auto/iaoumehwo25qwb01nbsi" },
      ],
    },
    imageSrc: {
      type: String,
      required: false,
      default: null,
    },
    overlayText: {
      type: Array,
      required: false,
      default: () => ["Welcome, let's raise a glass", "To a delightful experience"],
    },
  },
  data() {
    return {
      selectedVideoIndex: 0,
      showOverlay: true,
      dynamicMediaUrl: null,
    };
  },
  async mounted() {
    if (!this.imageSrc) {
      try {
        const q = query(collection(firestore, 'media'), where('section', '==', 'Home Video'));
        const querySnapshot = await getDocs(q);
        const mediaItems = [];
        querySnapshot.forEach((docSnap) => {
          let mediaItem = docSnap.data();
          mediaItem.id = docSnap.id;
          mediaItems.push(mediaItem);
        });
        if (mediaItems.length > 0) {
          // Pick a random media item from those uploaded for MenuVid
          const randomIndex = Math.floor(Math.random() * mediaItems.length);
          this.dynamicMediaUrl = mediaItems[randomIndex].url;
        }
      } catch (error) {
        console.error('Error fetching MenuVid media:', error);
      }
    }
    if (!this.imageSrc && !this.dynamicMediaUrl && this.videos.length > 0) {
      this.selectRandomVideo();
      this.setupLazyLoading();
    }
  },
  methods: {
    selectRandomVideo() {
      if (this.videos.length > 0) {
        this.selectedVideoIndex = Math.floor(Math.random() * this.videos.length);
      }
    },
    setupLazyLoading() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.loadVideo();
              observer.unobserve(entry.target);
            }
          });
        },
        {
          threshold: 0.5,
        }
      );
      observer.observe(this.$refs.videoPlayer);
    },
    loadVideo() {
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.src = this.videos[this.selectedVideoIndex].src;
      videoPlayer.autoplay = true;
      videoPlayer.play().catch((error) => console.log('Autoplay failed:', error));
    },
  },
};
</script>

<style scoped>
.video-container {
  height: 750px;
  background: #313131;
  opacity: 0.655;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.md-down-video-container {
  height: 650px;
}

.md--video-container {
border-radius: 50px;
}
.sm-down-video-container {
  height: 550px;
}
.overlay-text-item {
  white-space: pre-line;
  font-size: 4em;
  color: white;
  text-transform: uppercase;
  font-weight: lighter;
  text-align: center;
  font-family: NewYork;
  margin-bottom: 20px;
}
.md-down-overlay-text {
  font-size: 3em !important;
  line-height: 90px;
  font-weight: lighter !important;
}
.sm-down-overlay-text {
  font-size: 2em!important;
}
.video-element {
  object-fit: cover !important;
  width: 100%;
  height: 100%;
}
.video-element-md {
border-radius: 50px;
}

.overlay {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  border-radius: 50px;
  transform: translate(-50%, -50%);
}
.bd-rad {
  padding-top: 8px;
  padding-right: 13px;
}
.overlay-text-item {
  animation: fadePulse 7s ease-in-out infinite;
}

@keyframes fadePulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

</style>
