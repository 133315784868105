<!-- <template>
    <v-container fluid>
        <v-row class="overflow-hidden">
            <v-col cols="12" class="menu-vid">
                <MenuVid :imageSrc="imageSign" :overlayText="overlayMessages" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center">
            <v-col class="text-center" cols="12">
                <p class="font-format" :class="{ 'font-size-lg': $vuetify.display.smAndUp }">
                    We love creating special drinks that make your celebration unforgettable. 
                    Our signature mocktails are carefully crafted with a variety of flavours and ingredients to tantalise your taste buds. 
                    But that's not all - our candy floss, popcorn, and chocolate fountains are sure to delight both kids and adults.
                    We'll work with you to bring your vision to life.
                </p>
            </v-col>
            <v-col>
                <ChatUs />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import MenuVid from '@/components/MenuVid.vue';
import ChatUs from '@/components/ChatUs.vue'
    export default {
        name : "SignatureMocktails",
        components : {
            MenuVid,
            ChatUs,
        },
        data () {
            return {
              overlayMessages: ["Signature Mocktails","&" , "Sweet Treats"],
              imageSign: '/images/jpg/1.jpg'
            }
        }
    }
</script>

<style scoped>

</style> -->

<template>
    <v-container fluid>
      <v-row class="overflow-hidden">
        <v-col cols="12" class="menu-vid">
          <MenuVid :imageSrc="pageContent.imageUrl" :overlayText="pageContent.overlayMessages" />
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col class="text-center" cols="12">
          <p class="font-format" :class="{ 'font-size-lg': $vuetify.display.smAndUp }">
            {{ pageContent.paragraph }}
          </p>
        </v-col>
        <v-col>
          <ChatUs />
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import MenuVid from '@/components/MenuVid.vue';
  import ChatUs from '@/components/ChatUs.vue';
  import { firestore } from '@/firebase';
  import { collection, getDocs, query, where } from 'firebase/firestore';
  export default {
    name: "SignatureMocktails",
    components: { MenuVid, ChatUs },
    data() {
      return { pageContent: { imageUrl: '', overlayMessages: [], paragraph: '' } };
    },
    async mounted() {
      try {
        const q = query(collection(firestore, 'pageContent'), where('page', '==', 'Signature Mocktails'));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((docSnap) => { this.pageContent = docSnap.data(); });
      } catch (error) {
        console.error('Error fetching SignatureMocktails content:', error);
      }
    },
  };
  </script>

<style scoped>
.title {
    font-size: 3em!important;
    font-family: NewYork;
    text-align: center;
}
</style>