<!-- <template>
  <v-container fluid>
      <v-row class="overflow-hidden">
          <v-col cols="12" class="menu-vid">
              <MenuVid :imageSrc="imageEvent" :overlayText="overlayMessages" />
          </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col class="text-center" cols="12">
              <p class="font-format" :class="{ 'font-size-lg': $vuetify.display.smAndUp }">
                Let us take care of the drinks so you can focus on having fun. From sourcing a wide range of drinks, juices, and wines, to chilling them to perfection, and serving them with a smile, our team will work with you to create a custom beverage menu that fits your style and makes your event an absolute success.
              </p>
          </v-col>
          <v-col>
            <ChatUs />
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import MenuVid from '@/components/MenuVid.vue';
import ChatUs from '@/components/ChatUs.vue'
  export default {
      name : "EventBev",
      components : {
          MenuVid,
          ChatUs,
      },
      data () {
            return {
              overlayMessages: ["Events","&", "Drinks Management"],
              imageEvent: '/images/jpg/2.jpg'
            }
        }
  }
</script>

<style scoped>

</style> -->

<template>
  <v-container fluid>
    <v-row class="overflow-hidden">
      <v-col cols="12" class="menu-vid">
        <MenuVid :imageSrc="pageContent.imageUrl" :overlayText="pageContent.overlayMessages" />
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col class="text-center" cols="12">
        <p class="font-format" :class="{ 'font-size-lg': $vuetify.display.smAndUp }">
          {{ pageContent.paragraph }}
        </p>
      </v-col>
      <v-col>
        <ChatUs />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MenuVid from '@/components/MenuVid.vue';
import ChatUs from '@/components/ChatUs.vue';
import { firestore } from '@/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
export default {
  name: "EventBev",
  components: { MenuVid, ChatUs },
  data() {
    return { pageContent: { imageUrl: '', overlayMessages: [], paragraph: '' } };
  },
  async mounted() {
    try {
      const q = query(collection(firestore, 'pageContent'), where('page', '==', 'Event Beverage'));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((docSnap) => { this.pageContent = docSnap.data(); });
    } catch (error) {
      console.error('Error fetching EventBev content:', error);
    }
  },
};
</script>

<style scoped>
.title {
    font-size: 3em!important;
    font-family: NewYork;
    text-align: center;
}
</style>
