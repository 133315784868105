<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <v-card class="pa-4">
          <v-card-title class="headline">Admin Login</v-card-title>
          <v-card-text>
            <v-form ref="loginForm" @submit.prevent="login">
              <v-text-field v-model="email" label="Email" required />
              <v-text-field v-model="password" label="Password" type="password" required />
              <v-btn type="submit" color="#FFB300" block>Login</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" top timeout="5000">
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '@/firebase';

export default {
  name: 'AdminLogin',
  data() {
    return {
      email: '',
      password: '',
      snackbar: {
        show: false,
        message: '',
        color: 'success',
      },
    };
  },
  methods: {
    async login() {
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.showSnackbar("Login Successful!", "success");
        this.$router.push('/admin');
      } catch (error) {
        console.error('Login error:', error);
        this.showSnackbar("Login Failed!", "error");
      }
    },
    showSnackbar(message, color = 'success') {
      this.snackbar.message = message;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
  },
};
</script>

<style scoped>
* {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
