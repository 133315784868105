import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store"; // if using Vuex; otherwise, remove this
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "@/assets/css/fonts.css";
import { inject } from '@vercel/analytics';

inject();
loadFonts();

createApp(App).use(router).use(store).use(vuetify).mount("#app");
